<template>
  <div v-loading="loading" class="base-table">
    <el-table v-if="rows.length" :data="rows" style="width: 100%" @row-click="handleRowClick">
      <el-table-column
        v-for="(column, index) in columns"
        :key="`${column.label}-${index}`"
        :label="column.label"
        :prop="column.prop"
        :width="column.width"
        sortable
      >
        <template #default="scope">
          <span v-if="column.prop.includes('image')" :class="[column.prop]">
            <img src="" v-image-from-storage="scope.row[column.prop]" />
          </span>
          <span v-else :class="[column.prop]">
            <div>
              {{ scope.row[column.prop] }}
              <div
                class="club-status"
                v-if="column.label === 'Club name' && scope.row?.state === 'inactive'"
              >
                SUSPENDED
              </div>
            </div>
            <template v-if="column.label === 'Club name'">
              <div class="bottom-club-row">
                <div class="stripe-status" v-if="scope.row.stripe_account_id">Stripe connected</div>
                <div class="stripe-status error" v-else>Stripe is not connected</div>
              </div>
            </template>
          </span>
        </template>
      </el-table-column>
    </el-table>
    <el-empty v-else description="No rows yet" />
  </div>
</template>

<script>
export default {
  name: "BaseTable",
  props: ["rows", "columns", "path", "loading"],
  methods: {
    handleRowClick(value) {
      if (this.path) {
        if (value.uid) {
          this.$router.push({ path: this.path + value.uid });
        }

        if (value.id) {
          this.$router.push({ path: this.path + value.id });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.base-table {
  position: relative;
  margin-bottom: 27px;

  :deep(.el-table) {
    background: #fff;
    border-radius: 6px;
    box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.06);
  }

  :deep(.el-table__row) {
    cursor: pointer;
    border-bottom: 1px solid #ebeff2;
    gap: 24px;
    padding: 22px 24px;
    transition: background 0.2s ease;
    will-change: background;

    .cell {
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 19px;
      letter-spacing: 0.01em;
      color: #707683;
    }

    &:hover .cell {
      text-decoration: underline;
    }
  }

  :deep(.el-table__header) {
    th {
      text-transform: capitalize;
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 19px;
      letter-spacing: 0.01em;
      color: #323c47;

      .cell {
        display: flex;
        align-items: center;
        padding: 0 24px;
        justify-content: space-between;
      }
    }
  }

  :deep(.el-table .sort-caret.ascending),
  :deep(.el-table .sort-caret.descending) {
    border-bottom-color: transparent;
    border-top-color: transparent;
    display: none;
  }

  :deep(.el-table .ascending .sort-caret.ascending) {
    border-bottom-color: #000;
    display: block;
    top: 0;
  }

  :deep(.el-table .descending .sort-caret.descending) {
    border-top-color: #000;
    display: block;
    bottom: -2px;
  }

  :deep(.el-table__body-wrapper td) {
    padding: 20px 14px;
  }

  img {
    height: 40px;
    object-fit: contain;
    border-radius: 5px;
    background-color: #e1e4e9;
  }

  .stripe-status {
    font-size: 12px;
    font-weight: 600;
    color: #13b913;

    &.error {
      color: #ed1346;
    }
  }

  .bottom-club-row {
    display: flex;
    align-items: center;
    gap: 16px;
    flex-wrap: wrap;
    margin-top: 3px;
  }

  .club-status {
    display: inline-block;
    padding: 0 4px;
    font-size: 11px;
    font-weight: 600;
    border-radius: 5px;
    margin-left: 4px;
    background: #eaebef;
    color: #7e8695;
  }
}
</style>
