<template>
  <div class="base-header">
    <div class="container">
      <router-link class="base-header-logo" to="/">
        <inline-svg :src="require('@/assets/icons/logo.svg')" />
      </router-link>
      <button type="button" class="base-header-button" @click="handleLogOut">Log out</button>
    </div>
  </div>
</template>

<script>
import firebase from "@/config/firebase";
import InlineSvg from "vue-inline-svg";

export default {
  name: "BaseHeader",
  components: {
    InlineSvg,
  },
  methods: {
    async handleLogOut() {
      await firebase.auth().signOut();
      this.$router.push("/sign-in");
    },
  },
};
</script>

<style lang="scss" scoped>
.base-header {
  padding: 10px 0;
  width: 100%;
  background: #00001b;
}

.base-header-logo {
  display: block;
  font-size: 0;
  transition: opacity 0.2s ease;

  &:hover {
    opacity: 0.7;
  }
}

.container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.base-header-button {
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #a6aab4;
  appearance: none;
  border: none;
  background: none;
  font-family: inherit;
  transition: color 0.2s ease;

  &:hover {
    color: white;
  }
}
</style>
