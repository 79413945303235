<template>
  <div class="container">
    <BaseFilterBox v-model="filters" button-path="/clubs/create" button-text="Create a club" />
    <BaseTable
      v-loading="isLoading"
      :columns="columns"
      :rows="filteredRows"
      path="/clubs/edit/"
      @loadingStart="isLoading = true"
      @loadingFinish="isLoading = false"
    />
  </div>
  <router-view />
</template>

<script>
import firebase from "@/config/firebase";
import BaseFilterBox from "@/components/BaseFilterBox";
import BaseTable from "@/components/BaseTable";

export default {
  name: "Clubs",
  components: {
    BaseFilterBox,
    BaseTable,
  },
  data() {
    return {
      isLoading: false,
      filters: {
        search: "",
      },
      columns: [
        { label: "Club name", prop: "name", width: null },
        { label: "Logo", prop: "logo_image", width: "200px" },
        { label: "Splash image", prop: "splash_image", width: "200px" },
        { label: "Wallet image", prop: "wallet_image", width: "200px" },
        { label: "Subscriptions image", prop: "subscription_image", width: "200px" },
      ],
      rows: [],
    };
  },
  computed: {
    filteredRows() {
      let rows = this.rows;

      if (this.filters.search) {
        rows = rows.filter(
          (row) =>
            row.name && row.name.toLowerCase().indexOf(this.filters.search.toLowerCase()) !== -1
        );
      }

      return rows;
    },
  },
  mounted() {
    this.getClubs();
  },
  methods: {
    async getClubs() {
      this.isLoading = true;
      await firebase
        .firestore()
        .collection("clubs")
        .orderBy("state")
        .get()
        .then((snapshot) => {
          let result = snapshot.docs.map((doc) => {
            const data = doc.data();
            return { ...data, id: doc.id };
          });

          this.rows = result;
        });
      this.isLoading = false;
    },
  },
  beforeRouteUpdate(to, from, next) {
    if (from.name === "CreateClub") {
      this.getClubs();
    }
    if (from.name === "EditClub") {
      this.getClubs();
    }

    next();
  },
};
</script>

<style lang="scss" scoped></style>
