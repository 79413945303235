<template>
  <BaseHeader />
  <BaseNavigation :navigation="navigation" />
  <div class="app-container">
    <router-view />
  </div>
</template>

<script>
import BaseHeader from "@/components/BaseHeader";
import BaseNavigation from "@/components/BaseNavigation";
import firebaseInstance from "./config/firebase";

export default {
  name: "App",
  components: {
    BaseHeader,
    BaseNavigation,
  },
  data() {
    return {
      redirectLogicInit: false,
      navigation: [
        { label: "Home", path: "/", icon: "nav-home" },
        { label: "Clubs", path: "/clubs", icon: "nav-clubs" },
        { label: "Users", path: "/users", icon: "nav-users" },
        { label: "Settings", path: "/settings", icon: "nav-settings" },
      ],
    };
  },
  mounted() {
    firebaseInstance.auth().onAuthStateChanged((user) => {
      if (!user) {
        this.$router.push("/sign-in");
        console.log("Not logged in user was redirected.");
      }

      if (!this.redirectLogicInit) {
        this.$router.beforeEach((route) => {
          if (!route.path.includes("sign-in") && !firebaseInstance.auth().currentUser) {
            this.$router.push("/sign-in");
            console.log("Not logged in user was redirected.");
          }

          this.redirectLogicInit = true;
        });

        if (this.$route.query?.stripeError === true) {
          alert(
            "There was an error during Stripe Account connection. Please click the button on Clubs page to connect the Stripe account to your club."
          );
        }
      }
    });
  },
};
</script>

<style>
@import "~@/assets/css/fonts.css";
@import "~@/assets/css/reset.css";
@import "~@/assets/css/theme.css";

.app-container {
  padding: 48px 0;
}
</style>
