<template>
  <div class="base-date-picker">
    <el-date-picker
      v-model="formValue"
      type="date"
      :placeholder="placeholder"
      format="DD/MM/YYYY"
      value-format="DD/MM/YYYY"
    />
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "BaseDatePicker",
  props: {
    modelValue: {
      type: [Date, String, Number],
    },
    placeholder: {
      type: String,
    },
  },
  computed: {
    formValue: {
      get() {
        return moment(this.modelValue).format("DD/MM/YYYY");
      },
      set(value) {
        this.$emit("update:modelValue", moment(value, "DD/MM/YYYY").valueOf());
      },
    },
  },
};
</script>

<style lang="scss">
.base-date-picker {
  width: 100%;
}

.base-date-picker .el-date-editor,
.base-date-picker .date-picker-trigger {
  height: 100%;
  width: 100% !important;
}

.base-date-picker .el-input__prefix {
  height: 100%;
  line-height: 55px;
  left: 12px;
}

.base-date-picker .el-input__suffix {
  line-height: 55px;
  height: 100%;
}

.base-date-picker .el-input__inner {
  height: 100%;
  width: 100%;
  font-style: normal;
  font-family: "Sarabun", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.2px;
  color: #323c47;
  border: 1px solid rgba(166, 170, 180, 0.5);
  border-radius: 6px;
  padding: 16px 20px;
  padding-left: 42px !important;
  outline: none;
  transition: border-color 0.2s ease;
  will-change: border-color;
}

.base-date-picker .el-date-editor .el-input.is-focus .el-input__inner,
.base-date-picker .el-date-editor .el-input__inner:focus {
  border-color: #0ba3a9;
}

.base-date-picker::placeholder {
  color: rgba(166, 170, 180, 1);
}

.el-picker__popper {
  z-index: 9999 !important;
}
</style>
