<template>
  <form action="#" @submit.prevent="handleContinue" class="sign-in">
    <div class="sign-in-container" v-loading="isLoading">
      <div class="sign-in-title">Sign In</div>
      <div class="sign-in-row">
        <BaseInput
          v-model="form.email"
          placeholder="Email"
          @keyup.enter="handleContinue"
          autocomplete="email"
          type="email"
        />
      </div>
      <div class="sign-in-row">
        <BaseInput
          v-model="form.password"
          placeholder="Password"
          type="password"
          @keyup.enter="handleContinue"
          autocomplete="current-password"
        />
      </div>
      <div class="sign-in-row">
        <BaseButton type="submit"> Continue </BaseButton>
        <div class="sign-in-error">
          {{ error }}
        </div>
      </div>
    </div>
  </form>
</template>
<script>
import firebase from "@/config/firebase";
import BaseButton from "@/components/BaseButton";
import BaseInput from "@/components/BaseInput";

export default {
  name: "SignIn",
  components: {
    BaseButton,
    BaseInput,
  },
  data() {
    return {
      isLoading: false,
      form: {
        email: "",
        password: "",
      },
      error: "",
    };
  },
  computed: {
    isLoggedIn() {
      return firebase.auth().currentUser !== null;
    },
  },
  watch: {
    isLoggedIn: {
      handler() {
        if (this.isLoggedIn) {
          this.$router.push("/");
        }
      },
      immediate: true,
    },
  },
  methods: {
    async handleContinue() {
      this.error = "";
      this.isLoading = true;

      if (this.form.email !== "admin@myklubba.com") {
        this.error = "This user does not exist";
        this.isLoading = false;
        return;
      }

      await firebase
        .auth()
        .signInWithEmailAndPassword(this.form.email, this.form.password)
        .then(() => {
          this.isLoading = false;
          this.$router.push("/");
        })
        .catch((err) => {
          this.error = err.message;
          this.isLoading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.sign-in {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #f9fafb;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  padding: 20px;
}

.sign-in-container {
  width: 500px;
  background: #ffffff;
  border-radius: 8px;
  padding: 48px;
  margin: auto;
}

.sign-in-title {
  font-style: normal;
  font-weight: 600;
  text-align: center;
  font-size: 32px;
  line-height: 42px;
  letter-spacing: 0.5px;
  color: #0ba3a9;
  margin-bottom: 40px;
}

.sign-in-row {
  margin-bottom: 24px;
  position: relative;
}

.sign-in :deep(.base-button) {
  max-width: 100%;
}

.sign-in-error {
  position: absolute;
  top: calc(100% + 16px);
  width: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #f7685b;
}
</style>
