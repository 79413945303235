<template>
  <div class="container">
    <div class="navigation-list">
      <router-link
        class="navigation-item"
        v-for="(link, index) in navigation"
        :key="`navigation-link-${index}`"
        :to="link.path"
      >
        <div class="navigation-icon">
          <inline-svg :class="link.icon" :src="require(`@/assets/icons/${link.icon}.svg`)" />
        </div>
        <span>{{ link.label }}</span>
      </router-link>
    </div>
  </div>
</template>

<script>
import InlineSvg from "vue-inline-svg";

export default {
  name: "Home",
  components: {
    InlineSvg,
  },
  data() {
    return {
      navigation: [
        { label: "Create a club", path: "/clubs/create", icon: "nav-clubs" },
        { label: "Create a user", path: "/users/create", icon: "nav-users" },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.navigation-list {
  max-width: 730px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
}

.navigation-item {
  padding: 44px;
  min-height: 200px;
  background: #ffffff;
  box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

.navigation-icon {
  height: 48px;
  width: 48px;
  background: #0ba3a9;
  border-radius: 100px;
  margin-bottom: 16px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  :deep(path) {
    fill: #fff;
  }
}

.navigation-item span {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #3b414b;
}
</style>
