import firebaseInstance from "../config/firebase";

export async function getImageFromStorage(filePath) {
  let imageUrl = "";

  if (filePath?.length > 0) {
    await firebaseInstance
      .storage()
      .ref()
      .child(filePath)
      .getDownloadURL()
      .then((url) => {
        imageUrl = url;
      })
      .catch((error) => {
        console.error(`Could not load the image ${filePath}`, error.code);
      });
  }

  return imageUrl;
}

export const backgroundImageFromStorageDirective = {
  async mounted(el, binding) {
    if (binding.value !== binding.oldValue) {
      el.style.backgroundImage = `url(${await getImageFromStorage(binding.value)})`;
    }
  },
  async updated(el, binding) {
    if (binding.value !== binding.oldValue) {
      el.style.backgroundImage = `url(${await getImageFromStorage(binding.value)})`;
    }
  },
};

export const imageFromStorageDirective = {
  async mounted(el, binding) {
    if (binding.value !== binding.oldValue) {
      el.src = await getImageFromStorage(binding.value);
    }
  },
  async updated(el, binding) {
    if (binding.value !== binding.oldValue) {
      el.src = await getImageFromStorage(binding.value);
    }
  },
};
