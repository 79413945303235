<template>
  <button
    :type="buttonType"
    v-if="!to"
    class="base-button"
    :disabled="disabled"
    :class="[{ disabled: disabled, 'size-small': small }, `theme-${theme}`]"
  >
    <slot />
  </button>
  <router-link v-else-if="to" class="base-button" :class="{ disabled: disabled }" :to="to">
    <slot />
  </router-link>
</template>

<script>
export default {
  name: "BaseButton",
  props: {
    label: {
      type: String,
    },
    to: {
      type: [String, Object],
    },
    disabled: {
      type: Boolean,
    },
    type: {
      type: String,
      default: "button",
    },
    small: {
      type: Boolean,
    },
    theme: {
      type: String,
      default: "primary", // primary || error
    },
  },
};
</script>

<style lang="scss" scoped>
.base-button {
  max-width: 173px;
  width: 100%;
  padding: 14px 24px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.1px;
  color: #ffffff;
  background: #0ba3a9;
  border-radius: 6px;
  cursor: pointer;
  transition: opacity 0.2s ease, background-color 0.2s ease;
  will-change: opacity;
  appearance: none;
  display: block;
  border: none;
  font-family: inherit;

  &.theme-error {
    background: #cf153e;
  }

  &.size-small {
    padding: 8px 24px;
    width: auto;
    max-width: 100%;
  }

  &:hover {
    opacity: 0.7;
  }

  &.disabled {
    background: #777c7b;
    pointer-events: none;
    opacity: 0.5;
  }
}
</style>
