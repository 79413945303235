<template>
  <BaseModal :loading="isLoading" @close="$router.push('/clubs')">
    <SuspendClub
      v-if="uid && club?.state === 'active'"
      :is-visible="isHandleSuspendModalVisible"
      :name="club.name"
      @confirm="handleSuspendClubModalConfirm"
      @cancel="handleSuspendClubModalCancel"
    />
    <SuspendClub
      v-if="uid && club?.state === 'inactive'"
      :is-visible="isHandleSuspendModalVisible"
      :name="club.name"
      @confirm="handleActivateClubModalConfirm"
      @cancel="handleSuspendClubModalCancel"
      inactive
    />
    <!-- QR -->
    <div v-if="uid" class="create-club-title">Club QR Code</div>
    <div v-if="uid" class="create-club-qr">
      <div class="create-club-qr-code">
        <canvas id="preview-qrcode" ref="preview-qrcode" />
      </div>
      <div class="club-link">
        Club link:
        <a :href="`${baseURL}/${this.uid}`" target="_blank">{{ `${baseURL}/${this.uid}` }}</a>
      </div>
      <div class="club-link">
        Club admin link:
        <a :href="`${adminBaseURL}/`" target="_blank">
          {{ `${adminBaseURL}/` }}
        </a>
      </div>
      <div class="create-club-qr-button">
        <BaseButton @click="handleDownloadCode"> Download QR </BaseButton>
      </div>
    </div>

    <!-- Basic details -->
    <div class="create-club-title">Club details</div>
    <div class="create-club-margin">
      <div class="label">Club name</div>
      <BaseInput
        v-model="club.name"
        placeholder="Club name"
        :error="showErrors && club.name.length === 0"
      />
    </div>
    <div class="create-club-margin">
      <div class="label">Welcome title</div>
      <BaseInput
        v-model="club.welcome_title"
        placeholder="Welcome title"
        :error="showErrors && club.welcome_title.length === 0"
      />
    </div>
    <div class="create-club-margin">
      <BaseRichText
        v-model="club.welcome_message"
        title="Welcome message"
        :error="showErrors && club.welcome_message.length === 0"
      />
    </div>

    <!-- Account -->
    <div class="create-club-title">Club admin account</div>
    <div class="create-club-margin" :class="{ 'create-club-row': !uid }">
      <div>
        <div class="label">Email</div>
        <BaseInput
          v-model="club_admin_credentials.email"
          placeholder="Email"
          :disabled="uid"
          autocomplete="off"
          :error="showErrors && club_admin_credentials.email.length === 0"
        />
      </div>
      <div v-if="!uid">
        <div class="label">Password</div>
        <BaseInput
          v-model="club_admin_credentials.password"
          placeholder="Password"
          type="password"
          autocomplete="off"
          :error="showErrors && club_admin_credentials.password.length === 0"
        />
      </div>
      <div v-if="!uid">
        <div class="label">Confirm password</div>
        <BaseInput
          v-model="club_admin_credentials.password_confirmation"
          placeholder="Password confirmation"
          type="password"
          autocomplete="off"
          :error="showErrors && club_admin_credentials.password_confirmation.length === 0"
        />
      </div>
    </div>
    <div
      v-if="club_admin_credentials.email !== '' && !validateEmail(club_admin_credentials.email)"
      class="error-block"
    >
      Please, type correct email
    </div>
    <div
      v-if="club_admin_credentials.password !== club_admin_credentials.password_confirmation"
      class="error-block"
    >
      Passwords do not match
    </div>

    <div class="create-club-margin">
      <div class="label">Phone number</div>
      <BaseInput
        v-model="club.phone_number"
        placeholder="Enter contact phone number"
        :error="showErrors && club.phone_number.length === 0"
      />
    </div>

    <div v-if="club.phone_number !== '' && !validatePhone(club.phone_number)" class="error-block">
      Please, type correct phone number
    </div>

    <!-- Colors -->
    <div class="create-club-colors">
      <div class="create-club-title">Club colors</div>
      <div class="create-club-row create-club-margin">
        <div>
          <div class="label">Primary fore color</div>
          <BaseColorPicker
            v-model="club.primary_fore_color"
            placeholder="Pick or paste primary fore color"
            :error="showErrors && club.primary_fore_color.length === 0"
          />
        </div>
        <div>
          <div class="label">Primary back color</div>
          <BaseColorPicker
            v-model="club.primary_back_color"
            placeholder="Pick or paste primary back color"
            :error="showErrors && club.primary_back_color.length === 0"
          />
        </div>
        <div>
          <div class="label">Secondary fore color</div>
          <BaseColorPicker
            v-model="club.secondary_fore_color"
            placeholder="Pick or paste secondary fore color"
            :error="showErrors && club.secondary_fore_color.length === 0"
          />
        </div>
      </div>
      <div class="create-club-row">
        <div>
          <div class="label">Secondary back color</div>
          <BaseColorPicker
            v-model="club.secondary_back_color"
            placeholder="Pick or paste secondary back color"
            :error="showErrors && club.secondary_back_color.length === 0"
          />
        </div>
        <div>
          <div class="label">Disabled fore color</div>
          <BaseColorPicker
            v-model="club.disabled_fore_color"
            placeholder="Pick or paste disabled fore color"
            :error="showErrors && club.disabled_fore_color.length === 0"
          />
        </div>
      </div>
    </div>

    <!-- Passkit -->
    <div class="create-club-passkit">
      <div class="create-club-title">Passkit Card Styling</div>
      <div class="passkit-wrapper">
        <div class="left">
          <!-- Lockscreen -->
          <div>
            <div class="label">Lockscreen Icon</div>
            <div class="small-description">
              The lockscreen icon is mandatory and must be 1:1 ratio (square). The minimum required
              size is 114px x 114px at 72dpi in PNG or JPG format.
            </div>
            <BaseUpload
              v-model="club.card_style.lockscreen_icon"
              dimensions="114x114"
              placeholder="Lockscreen Icon"
              :error="showErrors && club.card_style.lockscreen_icon.length === 0"
            />
          </div>

          <!-- iOS Logo -->
          <div>
            <div class="label">Logo iOS</div>
            <div class="small-description">
              You can upload a square or rectangular logo for your iOS wallet pass. The recommended
              dimensions for a rectangular logo are 480px x 150px at 72dpi in PNG or JPG format.
            </div>
            <BaseUpload
              v-model="club.card_style.ios_logo"
              dimensions="480x150"
              placeholder="iOS Logo"
              :error="showErrors && club.card_style.ios_logo.length === 0"
            />
          </div>

          <!-- Android Logo -->
          <div>
            <div class="label">Logo Android</div>
            <div class="small-description">
              You can upload a square logo for your Android wallet pass. The recommended dimensions
              for a square logo are 660px x 660px at 72dpi in PNG or JPG format.
            </div>
            <BaseUpload
              v-model="club.card_style.android_logo"
              dimensions="660x660"
              placeholder="Android Logo"
              :error="showErrors && club.card_style.android_logo.length === 0"
            />
          </div>

          <!-- Hero Image -->
          <div>
            <div class="label">Hero Image</div>
            <div class="small-description">
              The hero image is the large banner image across your wallet pass. This is the best
              place to add your unique branding. The recommended dimensions are 1125px x 432px at
              72dpi in PNG or JPG format.
            </div>
            <BaseUpload
              v-model="club.card_style.hero_image"
              dimensions=">1124x0"
              placeholder="Hero Image"
              :error="showErrors && club.card_style.hero_image.length === 0"
            />
          </div>

          <!-- Background color -->
          <div>
            <div class="label">Background color</div>
            <BaseColorPicker
              v-model="club.card_style.background_color"
              placeholder="Background color"
              :error="showErrors && club.card_style.background_color.length === 0"
            />
          </div>

          <!-- Label -->
          <div>
            <div class="label">Label color</div>
            <BaseColorPicker
              v-model="club.card_style.label_color"
              placeholder="Label color"
              :error="showErrors && club.card_style.label_color.length === 0"
            />
          </div>

          <!-- Text color -->
          <div>
            <div class="label">Text color</div>
            <BaseColorPicker
              v-model="club.card_style.text_color"
              placeholder="Text color"
              :error="showErrors && club.card_style.text_color.length === 0"
            />
          </div>
        </div>

        <div class="right">
          <PasskitPreview :club="club" :card-style="club.card_style" />
        </div>
      </div>
    </div>

    <!-- Images -->
    <div class="create-club-images">
      <div class="create-club-title">Web App Club Branding</div>
      <div class="create-club--margin">
        <div class="label">Logo</div>
        <div class="small-description">
          To be displayed on the web-app at the top of most pages. The recommended dimensions are
          320px x 320px at 72dpi in PNG or JPG format.
          <BaseUpload
            v-model="club.logo_image"
            placeholder="Choose logo"
            :error="showErrors && club.logo_image.length === 0"
          />
        </div>
      </div>
      <div class="create-club--margin">
        <div class="label">Splash image</div>
        <div class="small-description">
          To be displayed on the web-app welcome page. The recommended dimensions are 400px x 225px
          at 72dpi in PNG or JPG format.
          <BaseUpload
            v-model="club.splash_image"
            placeholder="Choose splash image"
            :error="showErrors && club.splash_image.length === 0"
          />
        </div>
      </div>
      <div class="create-club--margin">
        <div class="label">Wallet image</div>
        <div class="small-description">
          To be displayed on the web-app when previewing what the wallet pass may look like. The
          recommended dimensions are 400px x 195px at 72dpi in PNG or JPG format.
        </div>
        <BaseUpload
          v-model="club.wallet_image"
          placeholder="Choose wallet image"
          :error="showErrors && club.wallet_image.length === 0"
        />
      </div>
      <div class="create-club--margin">
        <div class="label">Subscription background</div>
        <div class="small-description">
          To be displayed on the web-app when showing a subscription product. The recommended
          dimensions are 420px x 160px at 72dpi in PNG or JPG format.
        </div>
        <BaseUpload
          v-model="club.subscription_image"
          placeholder="Choose subscription background"
          :error="showErrors && club.subscription_image.length === 0"
        />
      </div>
    </div>

    <!-- Additional -->
    <div class="create-club-additional">
      <div class="create-club-title">Club additional information</div>
      <div class="create-club-row-2 create-club-margin">
        <div class="create-club-card">
          <div class="create-club-card-title">Medical conditions</div>
          <div
            class="create-club-card-input"
            v-for="(option, index) in club.medical_conditions"
            :key="`medical-option-${index}`"
          >
            <BaseInput
              v-model="club.medical_conditions[index]"
              :error="showErrors && club.medical_conditions[index].length === 0"
            />
            <div class="create-club-remove" @click="handleRemoveMedicalCondition(index)">
              <inline-svg
                class="create-club-remove-icon"
                :src="require('@/assets/icons/remove.svg')"
              />
            </div>
          </div>
          <BaseButton @click="handleAddMedicalCondition" small>Add answer</BaseButton>
        </div>
        <div class="create-club-card">
          <div class="create-club-card-title">Allergies</div>
          <div
            class="create-club-card-input"
            v-for="(option, index) in club.allergies"
            :key="`medical-option-${index}`"
          >
            <BaseInput
              v-model="club.allergies[index]"
              :error="showErrors && club.allergies[index].length === 0"
            />
            <div class="create-club-remove" @click="handleRemoveAllergy(index)">
              <inline-svg
                class="create-club-remove-icon"
                :src="require('@/assets/icons/remove.svg')"
              />
            </div>
          </div>
          <BaseButton @click="handleAddAllergy" small>Add answer</BaseButton>
        </div>
      </div>
    </div>

    <!-- Questions -->
    <div class="create-club-questions">
      <div class="create-club-title">Club questions</div>
      <div
        class="create-club-question"
        v-for="(question, index) in club.questions"
        :key="`question-${index}`"
      >
        <BaseInput
          v-model="question.text"
          :placeholder="`Question ${index + 1}`"
          :error="showErrors && question.text.length === 0"
        />
        <BaseCheckbox v-model="question.required" label="Mandatory" />
        <div class="create-club-remove" @click="handleRemoveQuestion(index)">
          <inline-svg class="create-club-remove-icon" :src="require('@/assets/icons/remove.svg')" />
          Remove
        </div>
      </div>
      <BaseButton @click="handleAddQuestion" small>Add new</BaseButton>
    </div>

    <!-- Subscriptions -->
    <!-- <div class="create-club-subscriptions">
      <div class="create-club-title">Club subscriptions</div>
      <div class="create-club-row">
        <BaseSubscription
          v-model="club_subscriptions[index]"
          v-for="(subscription, index) in club_subscriptions"
          :key="`club-subscription-${index}`"
          :remove-disabled="club_subscriptions.length > 1"
          :background-path="club.subscription_image"
          @remove="handleRemoveSubscription(index)"
        />
        <div
          v-if="club_subscriptions.length < 3"
          class="create-club-subscription-add"
          @click="handleAddSubscription"
        >
          <span>Add subscription</span>
        </div>
      </div>
    </div> -->

    <!-- Documents -->
    <div class="create-club-documents">
      <div class="create-club-title">Club documents</div>
      <div class="create-club-row-2">
        <BaseRichText
          v-model="club.terms_and_conditions"
          title="Terms and Conditions"
          :error="showErrors && club.terms_and_conditions.length === 0"
        />
        <BaseRichText
          v-model="club.privacy_policy"
          title="Privacy Policy"
          :error="showErrors && club.privacy_policy.length === 0"
        />
      </div>
    </div>

    <!-- Action -->
    <div class="create-club-button" :class="{ 'two-buttons': uid }">
      <BaseButton theme="error" v-if="uid && club?.state === 'active'" @click="handleSuspendClub"
        >Suspend club</BaseButton
      >
      <BaseButton v-if="uid && club?.state === 'inactive'" @click="handleActivateClub"
        >Activate club</BaseButton
      >
      <BaseButton @click="handleCreate">
        {{ uid ? "Apply changes" : "Create club" }}
      </BaseButton>
    </div>
    <div v-if="disableButton" class="error">Please fill in all fields to proceed</div>
  </BaseModal>
</template>

<script>
import BaseModal from "@/components/BaseModal";
import BaseButton from "@/components/BaseButton";
import BaseInput from "@/components/BaseInput";
import BaseCheckbox from "@/components/BaseCheckbox";
import BaseColorPicker from "@/components/BaseColorPicker";
import BaseUpload from "@/components/BaseUpload";
import BaseRichText from "@/components/BaseRichText";

import InlineSvg from "vue-inline-svg";
import QRCode from "qrcode";
import firebase from "@/config/firebase";
import PasskitPreview from "../components/PasskitPreview/PasskitPreview";
import SuspendClub from "@/components/CreateClub/SuspendClub";

import { useToast } from "vue-toastification";

export default {
  name: "CreateClub",
  components: {
    PasskitPreview,
    BaseModal,
    BaseButton,
    BaseInput,
    BaseCheckbox,
    BaseColorPicker,
    BaseUpload,
    BaseRichText,
    SuspendClub,
    InlineSvg,
  },
  data() {
    const toast = useToast();
    return {
      showErrors: false,
      toast: toast,
      qrCodeError: false,
      isLoading: false,
      clubId: null,
      isHandleSuspendModalVisible: false,
      not_required_fields: ["medical_conditions", "allergies", "stripe_account_id", "questions"],
      club: {
        name: "",
        phone_number: "",
        logo_image: "",
        splash_image: "",
        wallet_image: "",
        subscription_image: "",
        welcome_title: "",
        welcome_message: "",
        primary_fore_color: "",
        primary_back_color: "",
        secondary_back_color: "",
        disabled_fore_color: "",
        secondary_fore_color: "",
        allergies: [],
        medical_conditions: [],
        questions: [],
        terms_and_conditions: "",
        privacy_policy: "",
        stripe_account_id: null,
        card_style: {
          lockscreen_icon: "",
          ios_logo: "",
          android_logo: "",
          hero_image: "",
          background_color: "",
          label_color: "",
          text_color: "",
        },
      },
      club_admin_credentials: {
        email: "",
        password: "",
        password_confirmation: "",
      },
    };
  },
  computed: {
    baseURL() {
      return process.env.VUE_APP_BASE_URL;
    },
    adminBaseURL() {
      return process.env.VUE_APP_ADMIN_BASE_URL;
    },
    isSuspended() {
      return this.uid && this.club.state === "inactive";
    },
    uid() {
      return this.clubId ?? this.$route.params.id;
    },
    computedClub() {
      const style = this.club.card_style;
      const club = { ...this.club };
      delete club.card_style;

      return {
        ...this.club,
        ...style,
      };
    },
    computedClubRequiredOnly() {
      return Object.fromEntries(
        Object.entries(this.computedClub).filter(([key]) => !this.not_required_fields.includes(key))
      );
    },
    disableButton() {
      const adminVerifications =
        Object.values(this.club_admin_credentials).every((value) => value.length !== 0) &&
        this.validatePhone(this.club.phone_number) &&
        this.validateEmail(this.club_admin_credentials.email) &&
        this.club_admin_credentials.password === this.club_admin_credentials.password_confirmation;

      const anyUserVerifications =
        Object.values(this.computedClubRequiredOnly).every((value) => value.length !== 0) &&
        !(this.club.medical_conditions.filter((condition) => condition.length === 0)?.length > 0) &&
        !(this.club.allergies.filter((condition) => condition.length === 0)?.length > 0);

      return this.uid ? !anyUserVerifications : !(anyUserVerifications && adminVerifications);
    },
  },
  created() {
    if (this.uid) {
      this.getClub(this.uid);
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.uid) {
        this.generateQRCode();
      }
    });
  },
  methods: {
    validateEmail(email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(email);
    },
    validatePhone(phone) {
      let re = /^\+?[1-9]\d{1,14}$/;
      return re.test(phone);
    },
    handleDownloadCode() {
      const canvas = this.$refs["preview-qrcode"];
      let link = document.createElement("a");
      link.download = "qr-code.png";
      link.href = canvas.toDataURL();
      link.click();
      link.delete;
    },
    handleSuspendClub() {
      this.isHandleSuspendModalVisible = true;
    },
    handleActivateClub() {
      this.isHandleSuspendModalVisible = true;
    },
    async handleSuspendClubModalConfirm() {
      this.isLoading = true;

      const changeStatus = firebase.functions().httpsCallable("updateStateClub");
      try {
        await changeStatus({
          club_id: this.uid,
          state: "inactive",
        });

        this.isHandleSuspendModalVisible = false;
        await this.$router.push("/clubs");
      } catch (error) {
        console.log(error);
        this.toast.error(
          "Sorry, we can't change the status of this club at the moment. Please try again later."
        );
      }

      this.isLoading = false;
    },
    async handleActivateClubModalConfirm() {
      this.isLoading = true;

      const changeStatus = firebase.functions().httpsCallable("updateStateClub");
      try {
        await changeStatus({
          club_id: this.uid,
          state: "active",
        });

        this.isHandleSuspendModalVisible = false;
        await this.$router.push("/clubs");
      } catch (error) {
        console.log(error);
        this.toast.error(
          "Sorry, we can't change the status of this club at the moment. Please try again later."
        );
      }

      this.isLoading = false;
    },
    handleSuspendClubModalCancel() {
      this.isHandleSuspendModalVisible = false;
    },
    generateQRCode() {
      const qrCodeCanvas = this.$refs["preview-qrcode"];
      QRCode.toCanvas(qrCodeCanvas, `${this.baseURL}/${this.uid}`, (error) => {
        if (error) {
          this.qrCodeError = true;
          console.error({ QRCodeGenerationError: error });
        }
      });
    },
    handleAddQuestion() {
      this.club.questions.push({ text: "", required: false });
    },
    handleAddMedicalCondition() {
      this.club.medical_conditions.push("");
    },
    handleAddAllergy() {
      this.club.allergies.push("");
    },
    handleRemoveQuestion(index) {
      this.club.questions = this.club.questions
        .slice(0, index)
        .concat(this.club.questions.slice(index + 1, this.club.questions.length));
    },
    handleRemoveMedicalCondition(index) {
      this.club.medical_conditions = this.club.medical_conditions
        .slice(0, index)
        .concat(this.club.medical_conditions.slice(index + 1, this.club.medical_conditions.length));
    },
    handleRemoveAllergy(index) {
      this.club.allergies = this.club.allergies
        .slice(0, index)
        .concat(this.club.allergies.slice(index + 1, this.club.allergies.length));
    },
    async getClub(id) {
      this.isLoading = true;
      const user = await firebase
        .firestore()
        .collection("club_admins")
        .where("club_id", "==", id)
        .get();
      this.club_admin_credentials.email = user.docs[0].data().email;

      const club = await firebase.firestore().collection("clubs").doc(id).get();
      const clubData = club.exists ? club.data() : null;

      if (clubData !== null) {
        this.club = JSON.parse(JSON.stringify(clubData));
      }

      this.isLoading = false;
    },
    async createAdminUser(club_id) {
      const createAdminUser = await firebase.functions().httpsCallable("createAdminUser");
      const userEntity = await createAdminUser({
        email: this.club_admin_credentials.email,
        password: this.club_admin_credentials.password,
      });

      if (userEntity.data.isSuccess) {
        const uid = await userEntity.data.result.uid;
        await firebase.firestore().collection("club_admins").doc(uid).set({
          email: this.club_admin_credentials.email,
          club_id: club_id,
          user_id: uid,
        });
      } else {
        this.toast.error(userEntity.data.error.message);
      }
    },
    async handleCreate() {
      // Prevent proceeding with empty fields
      if (this.disableButton) {
        this.toast.error("Please fill in all fields to proceed");
        this.showErrors = true;
        return;
      }

      this.isLoading = true;
      if (this.uid) {
        try {
          const updateClubCard = await firebase.functions().httpsCallable("updateClubCard");
          const cardEntity = await updateClubCard({
            card_style: this.club.card_style,
            club_id: this.uid,
          });

          if (cardEntity.data.isSuccess) {
            await firebase.firestore().collection("clubs").doc(this.uid).update(this.club);
          } else {
            this.toast.error(
              "Sorry, club update was not successful, please try again or contact support"
            );
          }
        } catch (error) {
          this.toast.error(
            "Sorry, club update was not successful, please try again or contact support"
          );
        }
      } else {
        try {
          const adminExists = await firebase
            .firestore()
            .collection("club_admins")
            .where("email", "==", this.club_admin_credentials.email)
            .get();
          if (adminExists.empty && this.club_admin_credentials.email !== "admin@myklubba.com") {
            const club = await firebase
              .firestore()
              .collection("clubs")
              .add({
                ...this.club,
                state: "active",
                email: this.club_admin_credentials.email,
              });

            this.clubId = club.id;
            await this.createAdminUser(club.id);
            await this.$router.push("/clubs");
          } else {
            this.toast.error(
              "Sorry, this admin account already exists. Please use different email"
            );
          }
        } catch (error) {
          this.toast.error(
            "Sorry, club update was not successful, please try again or contact support"
          );
        }
      }
      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.create-club-title {
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 42px;
  letter-spacing: 0.5px;
  color: #0ba3a9;
  margin-bottom: 24px;
}

.create-club-qr {
  margin-bottom: 40px;
}

.create-club-qr-code {
  margin-bottom: 24px;
  display: inline-block;
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.06);
}

.create-club-questions,
.create-club-subscriptions,
.create-club-colors,
.create-club-images,
.create-club-additional,
.create-club-documents {
  margin-top: 40px;
}

.create-club-question {
  margin-bottom: 25px;
  gap: 24px;
  display: flex;
  align-items: center;
}

.create-club-button {
  margin-top: 48px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  &.two-buttons {
    flex-direction: row;
    justify-content: space-between;
  }
}

.create-club-margin {
  margin-bottom: 25px;
}

.create-club-row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 16px;
}

.create-club-row-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}

.create-club-subscription-add {
  min-height: 598px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #e8e9ec;
  border-radius: 8px;
  padding: 24px;
}

.create-club-subscription-add span {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.1px;
  color: #ffffff;
  background: #0ba3a9;
  border-radius: 6px;
  padding: 12px 24px;
  cursor: pointer;
}

.create-club-card {
  background: #ffffff;
  border: 1px solid rgba(166, 170, 180, 0.5);
  border-radius: 8px;
  padding: 24px;
}

.create-club-card-input {
  margin-bottom: 25px;
  display: flex;
  gap: 16px;
}

.create-club-card-input:last-child {
  margin-bottom: 0;
}

.create-club-card-title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #0ba3a9;
  margin-bottom: 24px;
}

.create-club-remove {
  cursor: pointer;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

.create-club-remove-icon {
  height: 24px;
  width: 24px;
  border-radius: 100px;

  :deep(path) {
    fill: #f7685b;
  }
}

.create-club-question .create-club-remove {
  height: 32px;
  border-left: 1px solid rgba(166, 170, 180, 0.5);
  padding-left: 24px;
  flex-flow: row nowrap;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: #111111;
}

.create-club-question .create-club-remove-icon {
  margin-right: 12px;
}

.error {
  color: #de1534;
  margin-top: 20px;
  text-align: center;
}

.error-block {
  color: #de1534;
  padding: 10px 15px;
  border-radius: 5px;
  background: #ffedf3;
  line-height: 1.5;
  font-size: 14px;
  margin-bottom: 25px;
}

.label {
  margin-bottom: 12px;
  color: #878d93;
  font-size: 14px;
}

.club-link {
  margin-bottom: 20px;

  a {
    font-weight: 500;
    color: #0ba3a9;
    transition: opacity 0.2s ease;

    &:hover {
      opacity: 0.5;
    }
  }
}

.create-club-passkit {
  margin-top: 40px;
}

.passkit-wrapper {
  display: flex;

  .left {
    display: flex;
    flex-direction: column;

    > div {
      &:not(:first-child) {
        margin-top: 30px;
      }
    }
  }

  .right {
    margin-left: 80px;
  }
}

.small-description {
  margin-bottom: 14px;
  font-size: 14px;
  line-height: 1.5;
}
</style>
