<template>
  <div class="base-modal">
    <div class="base-modal-background" @click="handleClose" />
    <div v-loading="loading" class="base-modal-content">
      <inline-svg
        class="base-modal-close"
        :src="require('@/assets/icons/close.svg')"
        @click="handleClose"
      />
      <slot />
    </div>
  </div>
</template>

<script>
import InlineSvg from "vue-inline-svg";

export default {
  name: "BaseModal",
  props: ["loading"],
  components: {
    InlineSvg,
  },
  methods: {
    handleClose() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.base-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 120px 0;
  z-index: 9999;
  overflow: auto;
}

.base-modal-background {
  background: #171d33;
  opacity: 0.8;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.base-modal-close {
  position: absolute;
  top: -54px;
  right: 4px;
  cursor: pointer;
}

.base-modal-content {
  padding: 52px 24px;
  position: relative;
  margin: 0 auto;
  width: 1110px;
  z-index: 1;
  background: #ffffff;
  box-shadow: 0px 16px 56px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
}
</style>
