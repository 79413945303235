<template>
  <div class="base-filter-box">
    <div class="base-filter-box-search">
      <input type="text" v-model="formValue.search" placeholder="Search" />
      <inline-svg :src="require('@/assets/icons/search.svg')" />
    </div>

    <div class="base-filter-box-button" @click="$router.push(buttonPath)">
      {{ buttonText }}
    </div>
  </div>
</template>

<script>
import InlineSvg from "vue-inline-svg";

export default {
  name: "BaseFilterBox",
  components: {
    InlineSvg,
  },
  props: ["buttonText", "buttonPath", "modelValue"],
  computed: {
    formValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.base-filter-box {
  margin-bottom: 19px;
  background: #ffffff;
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  padding: 14px 20px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  gap: 34px;
}

.base-filter-box-search {
  position: relative;
  flex: 0 0 415px;

  input {
    width: 100%;
    background: rgba(240, 241, 244, 0.8);
    border-radius: 6px;
    padding: 7px 40px 7px 16px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #323c47;
    line-height: 18px;
    letter-spacing: 0.2px;
    outline: none;
    height: initial;
    border: none;
  }

  input::placeholder {
    color: #a6aab4;
  }

  svg {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.base-filter-box-tabs {
  flex: 1;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 34px;
}

.base-filter-box-tabs-item {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  text-decoration: underline;
  color: #a6aab4;
  cursor: pointer;
}

.base-filter-box-button {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.1px;
  color: #ffffff;
  background: #0ba3a9;
  border-radius: 6px;
  padding: 4px 24px;
  cursor: pointer;
}
</style>
