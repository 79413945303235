<template>
  <div class="container">
    <BaseFilterBox v-model="filters" button-path="/users/create" button-text="Create a user" />
    <BaseTable v-loading="isLoading" :columns="columns" :rows="filteredRows" path="/users/edit/" />
  </div>
  <router-view />
</template>

<script>
import firebase from "@/config/firebase";
import BaseTable from "@/components/BaseTable";
import BaseFilterBox from "@/components/BaseFilterBox";

export default {
  name: "Users",
  components: {
    BaseTable,
    BaseFilterBox,
  },
  data() {
    return {
      isLoading: false,
      filters: {
        search: "",
      },
      columns: [
        { label: "First name", prop: "first_name", width: null },
        { label: "Last name", prop: "last_name", width: null },
        { label: "Email", prop: "email", width: null },
        { label: "Phone number", prop: "phone_number", width: null },
      ],
      rows: [],
    };
  },
  computed: {
    filteredRows() {
      let rows = this.rows;

      if (this.filters.search) {
        rows = rows.filter(
          (row) =>
            (row.name &&
              row.name.toLowerCase().indexOf(this.filters.search.toLowerCase()) !== -1) ||
            (row.email &&
              row.email.toLowerCase().indexOf(this.filters.search.toLowerCase()) !== -1) ||
            (row.phone && row.phone.toLowerCase().indexOf(this.filters.search.toLowerCase()) !== -1)
        );
      }

      return rows;
    },
  },
  mounted() {
    this.getUsers();
  },
  methods: {
    async getUsers() {
      this.isLoading = true;
      await firebase
        .firestore()
        .collection("users")
        .get()
        .then((snapshot) => {
          const result = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

          if (localStorage.getItem("deletedUser")) {
            this.rows = result.filter((user) => user.id !== localStorage.getItem("deletedUser"));
          } else {
            this.rows = result;
          }
        });

      this.isLoading = false;
    },
  },
  beforeRouteUpdate(to, from, next) {
    if (from.name === "CreateUser") {
      this.getUsers();
    }
    if (from.name === "EditUser") {
      this.getUsers();
    }

    next();
  },
};
</script>

<style lang="scss" scoped></style>
