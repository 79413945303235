<template>
  <div
    class="passkit-preview"
    :style="{
      '--background-color':
        cardStyle?.background_color?.length > 0 ? cardStyle?.background_color : 'black',
      '--label-color': cardStyle?.label_color?.length > 0 ? cardStyle?.label_color : 'white',
      '--text-color': cardStyle?.text_color?.length > 0 ? cardStyle?.text_color : 'white',
    }"
  >
    <div class="toggle">
      <BaseCheckbox v-model="ios" :label="ios ? 'iOS Card Preview' : 'Android Card Preview'" />
    </div>

    <div class="card" :class="`platform-${ios ? 'ios' : 'android'}`">
      <!-- Header -->
      <div class="header">
        <!-- Logos -->
        <div class="logos">
          <img v-if="ios" class="logo ios" v-image-from-storage="cardStyle?.ios_logo ?? ''" />
          <div
            v-else
            class="logo android"
            v-bg-image-from-storage="cardStyle?.android_logo ?? ''"
          />
          <div v-if="!ios" class="title">{{ club?.name ?? "Club Name" }}</div>
        </div>

        <!-- Tier -->
        <div v-if="ios" class="tier">
          <div class="label">Tier</div>
          <div class="text">{{ subscriptions?.[0]?.name ?? "Subscription name" }}</div>
        </div>
      </div>

      <!-- Hero iOS -->
      <img v-if="ios" class="hero" v-image-from-storage="cardStyle?.hero_image ?? ''" />

      <!-- Tier name Android -->
      <div v-if="!ios" class="title-android">
        {{ subscriptions?.[0]?.name ?? "Subscription name" }}
      </div>

      <!-- Info -->
      <div class="info">
        <div>
          <div class="label">FIRST NAME</div>
          <div class="text">{{ user?.first_name ?? "John" }}</div>
        </div>
        <div>
          <div class="label">LAST NAME</div>
          <div class="text">{{ user?.last_name ?? "Doe" }}</div>
        </div>
      </div>

      <div class="code">
        <div class="barcode-image-wrapper">
          <img class="barcode-image" src="~./assets/barcode-example.png" />
        </div>
        <div class="barcode-text">50bc14c9-7e91-494f-8e15</div>
      </div>

      <!-- Hero Android -->
      <img v-if="!ios" class="hero" v-image-from-storage="cardStyle?.hero_image ?? ''" />
    </div>
  </div>
</template>

<script>
import BaseCheckbox from "../BaseCheckbox";

export default {
  name: "PasskitPreview",
  components: {
    BaseCheckbox,
  },
  props: {
    club: {
      type: Object,
    },
    user: {
      type: Object,
    },
    cardStyle: {
      type: Object,
    },
    subscriptions: {
      type: Array,
    },
  },
  data() {
    return {
      ios: true,
    };
  },
};
</script>

<style lang="scss" scoped>
.passkit-preview {
  width: 320px;

  .header {
    padding: 8px 16px;
    display: flex;
    align-items: center;
  }

  .logos {
    display: flex;
    align-items: center;
    gap: 16px;
    flex: 1;
    margin-right: 16px;
  }

  .title {
    font-size: 14px;
    line-height: 1.5;
    font-weight: 700;
  }

  .logo {
    flex-shrink: 0;
    transform: translate3d(0, 0, 0);

    &.ios {
      height: 40px;
    }

    &.android {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      overflow: hidden;
      background-size: cover;
    }
  }

  .card {
    background: var(--background-color);
    color: var(--text-color);
    overflow: hidden;
    transform: translate3d(0, 0, 0);

    &.platform-ios {
      border-radius: 4px;
      padding-bottom: 48px;

      .label {
        color: var(--label-color);
      }

      .code {
        background: white;
        font-size: 10px;
        color: #333;
        width: 226px;
        border-radius: 4px;
        padding-bottom: 3px;
      }

      .barcode-image-wrapper {
        padding: 8px 8px 0;
      }

      .info {
        padding: 8px 16px;
        margin-bottom: 80px;
      }
    }

    &.platform-android {
      border-radius: 15px;

      .code {
        margin-bottom: 12px;
        font-size: 14px;
        width: 246px;

        .barcode-image-wrapper {
          background: white;
          padding: 8px;
          border-radius: 4px;
          margin-bottom: 2px;
        }
      }

      .header {
        padding: 16px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.125);
      }

      .info {
        padding: 24px 16px;
      }
    }
  }

  .toggle {
    margin-bottom: 24px;
  }

  .label {
    font-size: 12px;
    text-transform: uppercase;
    line-height: 1.5;
  }

  .tier {
    text-align: right;
  }

  .text {
    font-size: 14px;
    line-height: 1.5;
    text-transform: uppercase;
    color: var(--text-color);
  }

  .info {
    display: flex;
    gap: 30px;
    justify-content: space-between;

    > div:last-child {
      text-align: right;
    }
  }

  .title-android {
    padding: 16px 16px 0;
    font-size: 18px;
    font-weight: bold;
  }

  .code {
    margin: 0 auto;
    text-align: center;
  }

  .barcode-image {
    max-width: 100%;
    display: block;
  }

  .hero {
    transform: translate3d(0, 0, 0);
    display: block;
    width: 100%;
  }
}
</style>
