<template>
  <div class="base-input" :class="{ 'has-error': error }">
    <div v-if="label" class="base-input-label">
      {{ label }}
    </div>
    <input
      class="base-input-field"
      :class="{ 'left-padding': leftPadding, disabled: disabled }"
      :type="type ? type : 'text'"
      v-model="formValue"
      :placeholder="placeholder"
      :disabled="disabled"
      :autocomplete="autocomplete"
    />
    <div v-if="errorText && error" class="error-text">{{ errorText }}</div>
  </div>
</template>

<script>
export default {
  name: "BaseInput",
  props: [
    "modelValue",
    "placeholder",
    "leftPadding",
    "type",
    "disabled",
    "autocomplete",
    "label",
    "error",
    "errorText",
  ],
  computed: {
    formValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.base-input {
  width: 100%;

  &.has-error {
    .base-input-field {
      border-color: #de1534;
    }
  }
}

.base-input-field {
  width: 100%;
  font-style: normal;
  font-family: "Sarabun", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.2px;
  color: #323c47;
  border: 1px solid rgba(166, 170, 180, 0.5);
  border-radius: 6px;
  padding: 16px 20px;
  outline: none;
  transition: border-color 0.2s ease;
  will-change: border-color;

  &:active,
  &:focus {
    border-color: #0ba3a9;
  }
}

.base-input::placeholder {
  color: rgba(166, 170, 180, 1);
}

.base-input-label {
  margin-bottom: 12px;
  color: #878d93;
  font-size: 14px;
}

.left-padding {
  padding-left: 40px;
}

.disabled {
  pointer-events: none;
}

.base-input::-webkit-outer-spin-button,
.base-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.error-text {
  color: #de1534;
  margin-top: 20px;
  text-align: left;
}
</style>
