<template>
  <div class="base-upload" v-loading="isLoading" :class="{ 'has-error': error }">
    <div class="base-upload-file">
      <div class="base-upload-file-preview">
        <template v-if="formValue">
          <div
            class="base-upload-file-preview-image"
            v-bg-image-from-storage="modelValue ?? image_path"
          />
        </template>
        <inline-svg
          v-else
          class="base-upload-file-preview-placeholder"
          :src="require('@/assets/icons/image-placeholder.svg')"
        />
      </div>
      <div v-if="formValue" class="base-upload-file-name">
        {{ formValue.replace("images/", "") }}
      </div>
      <div v-else class="base-upload-placeholder">
        {{ placeholder ? placeholder : "Choose an image" }}
      </div>
    </div>
    <el-upload
      ref="upload"
      class="base-upload-input"
      action=""
      :multiple="false"
      :http-request="onSubmit"
      :before-upload="beforeUpload"
      :show-file-list="false"
      :limit="1"
    >
      <div class="base-upload-button">Choose</div>
    </el-upload>
    <div v-if="errorText && error" class="error-text">{{ errorText }}</div>
  </div>
</template>

<script>
import firebase from "@/config/firebase";
import InlineSvg from "vue-inline-svg";

export default {
  name: "BaseUpload",
  props: ["modelValue", "placeholder", "dimensions", "error", "errorText"],
  components: {
    InlineSvg,
  },
  data() {
    return {
      image_path: "",
      isLoading: false,
      maximumFileSize: 6,
      imageURL: "",
    };
  },
  computed: {
    formValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  methods: {
    async onSubmit(req) {
      this.isLoading = true;

      const uuid = Math.random().toString(36).slice(-6);
      const imageName = req.file.name.replace(/ /g, "-");
      const bucket_url = `${uuid}_${imageName}`;
      const storage = firebase.storage();
      const ref = storage.ref().child(bucket_url);
      await ref.put(req.file);

      this.formValue = bucket_url;
      this.image_path = bucket_url;

      this.isLoading = false;
    },
    async beforeUpload(file) {
      this.$refs.upload.clearFiles();

      // Check file size
      const uploadExceedsMaximumFileSize = file.size / 1024 / 1024 > this.maximumFileSize;
      if (uploadExceedsMaximumFileSize) {
        alert(`Image size can not exceed ${this.maximumFileSize} MB!`);
        return Promise.reject();
      }

      // Check file type
      const validType = file.type.split("/")[0] === "image"; // Must be an image
      if (!validType) {
        alert("File must be an image!");
        return Promise.reject();
      }

      // Check image dimensions
      let validDimensions = true;
      if (this.dimensions) {
        const imageDimensions = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.onload = function () {
            const img = new Image();
            img.onload = function () {
              resolve([img.width, img.height]);
            };
            img.src = reader.result;
          };
          reader.readAsDataURL(file);
        });

        const dimensionsArray = this.dimensions.split("x");

        for (const i of [0, 1]) {
          // 0 means "any"
          if (dimensionsArray[i] !== "0" && validDimensions) {
            if (dimensionsArray[i].includes(">")) {
              // More
              if (Number(imageDimensions[i]) <= Number(dimensionsArray[i].split(">")[1])) {
                validDimensions = false;
                alert(
                  `Image should have ${i === 0 ? "width" : "height"} > ${
                    dimensionsArray[i].split(">")[1]
                  }!`
                );
              }
            } else if (dimensionsArray[i].includes("<")) {
              // Less
              if (Number(imageDimensions[i]) >= Number(dimensionsArray[i].split(">")[1])) {
                validDimensions = false;
                alert(
                  `Image should have ${i === 0 ? "width" : "height"} < ${
                    dimensionsArray[i].split(">")[1]
                  }!`
                );
              }
            } else if (String(imageDimensions[i]) !== dimensionsArray[i]) {
              // Equal
              validDimensions = false;
              alert(`Image should have size ${this.dimensions}!`);
            }
          }
        }

        if (!validDimensions) {
          return Promise.reject();
        }
      }

      return Promise.resolve();
    },
  },
};
</script>

<style lang="scss" scoped>
.base-upload {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid rgba(166, 170, 180, 0.5);
  border-radius: 6px;
  padding: 12px 12px;
  outline: none;
  background: #fff;
  transition: border-color 0.2s ease;
  will-change: border-color;

  &.has-error {
    border-color: #de1534;
  }
}

.error-text {
  color: #de1534;
  margin-top: 20px;
  text-align: left;
}

.base-upload-file {
  flex: 1;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.base-upload-file-preview {
  margin-right: 12px;
  border-radius: 4px;
  overflow: hidden;
  width: 32px;
  height: 32px;
  position: relative;
}

.base-upload-file-preview-image {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #eeeeee;
}

.base-upload-file-preview-placeholder {
  width: 100%;
  height: 100%;
}

.base-upload-file-name,
.base-upload-placeholder {
  flex: 1;
  font-style: normal;
  font-family: "Sarabun", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.2px;
  color: #323c47;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 0;
  padding-right: 20px;
}

.base-upload-button {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.1px;
  color: #ffffff;
  background: #0ba3a9;
  border-radius: 6px;
  padding: 4px 24px;
  cursor: pointer;
}
</style>
