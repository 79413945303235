<template>
  <BaseModal :loading="isLoading" @close="$router.push('/users')">
    <ChangePhone
      :is-visible="isPhoneChangeModalVisible"
      :step="phoneChangeModalStep"
      :phone-number="user.phone_number"
      @cancel="handlePhoneChangeModalCancel"
      @confirm="handlePhoneChangeModalConfirm"
    />
    <div class="create-user-title">Account details</div>
    <div class="create-user-row create-user-margin">
      <div class="create-user-phone">
        <div v-if="uid" class="create-user-phone-action" @click="handlePhoneEdit">Edit</div>
        <BaseInput v-model="user.phone_number" :disabled="uid" placeholder="Phone number" />
      </div>
      <BaseInput v-model="user.email" placeholder="Email" />
    </div>
    <div v-if="user.phone_number !== '' && !validatePhone(user.phone_number)" class="error-block">
      Please, type correct phone number
    </div>
    <div v-if="user.email !== '' && !validateEmail(user.email)" class="error-block">
      Please, type correct email
    </div>
    <div class="create-user-title">User details</div>
    <div class="create-user-row create-user-margin">
      <BaseInput v-model="user.first_name" placeholder="First name" />
      <BaseInput v-model="user.last_name" placeholder="Last name" />
    </div>
    <div class="create-user-row create-user-margin">
      <BaseDatePicker v-model="user.date_of_birth" placeholder="Date of birth" />
      <BaseSelect v-model="user.gender" :options="gender_options" placeholder="Gender" />
    </div>
    <div class="create-user-button" :class="{ 'two-buttons': uid }">
      <BaseButton theme="error" v-if="uid" @click="handleDelete">Delete user</BaseButton>
      <BaseButton @click="handleCreate" :disabled="disableButton">
        {{ uid ? "Apply changes" : "Create user" }}
      </BaseButton>
    </div>
  </BaseModal>
</template>

<script>
import BaseModal from "@/components/BaseModal";
import BaseButton from "@/components/BaseButton";
import BaseInput from "@/components/BaseInput";
import BaseSelect from "@/components/BaseSelect";
import BaseDatePicker from "@/components/BaseDatePicker";
import ChangePhone from "@/components/CreateUser/ChangePhone";

import { useToast } from "vue-toastification";

import firebase from "@/config/firebase";

export default {
  name: "CreateUser",
  components: {
    BaseModal,
    BaseButton,
    BaseInput,
    BaseSelect,
    ChangePhone,
    BaseDatePicker,
  },
  data() {
    const toast = useToast();
    return {
      toast: toast,
      isLoading: false,
      phoneChangeModalConfirmed: false,
      phoneChangeModalStep: 0,
      isPhoneChangeModalVisible: false,
      new_phone_number: "",
      user: {
        type: "user",
        phone_number: "",
        email: "",
        first_name: "",
        last_name: "",
        date_of_birth: null,
        gender: "",
      },
      gender_options: [
        { label: "Rather not say", value: "not-provided" },
        { label: "Male", value: "male" },
        { label: "Female", value: "female" },
      ],
    };
  },
  computed: {
    uid() {
      return this.$route.params.id;
    },
    computedUser() {
      return this.user;
    },
    disableButton() {
      return !(
        this.computedUser.phone_number !== "" &&
        this.computedUser.email !== "" &&
        this.computedUser.first_name !== "" &&
        this.computedUser.last_name !== "" &&
        this.computedUser.date_of_birth !== null &&
        this.computedUser.gender !== "" &&
        this.validatePhone(this.computedUser.phone_number) &&
        this.validateEmail(this.computedUser.email)
      );
    },
  },
  created() {
    if (this.uid) {
      this.getUser(this.uid);
    }
  },
  methods: {
    validateEmail(email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(email);
    },
    validatePhone(phone) {
      let re = /^\+?[1-9]\d{1,14}$/;
      return re.test(phone);
    },
    handlePhoneChangeModalConfirm() {
      this.phoneChangeModalConfirmed = true;
      if (this.phoneChangeModalStep === 0 && this.phoneChangeModalConfirmed) {
        this.phoneChangeModalStep = 1;
        this.phoneChangeModalConfirmed = false;
      }

      if (this.phoneChangeModalStep === 1 && this.phoneChangeModalConfirmed) {
        this.phoneChangeModalStep = 2;
        this.phoneChangeModalConfirmed = false;
      }

      if (this.phoneChangeModalStep === 2) {
        setTimeout(() => {
          this.phoneChangeModalStep = 3;
        }, 5000);
      }
    },
    handlePhoneChangeModalCancel() {
      this.isPhoneChangeModalVisible = false;
      this.phoneChangeConfirmed = false;
      this.new_phone_number = "";

      if (this.phoneChangeModalStep === 3) {
        this.$router.push("/users");
        this.phoneChangeModalStep = 0;
      } else {
        this.phoneChangeModalStep = 0;
      }
    },
    handlePhoneEdit() {
      this.isPhoneChangeModalVisible = true;
    },
    async getUser(id) {
      this.isLoading = true;
      const user = await firebase.firestore().collection("users").doc(id).get();
      const userData = user.exists ? user.data() : null;

      if (userData !== null) {
        this.user = JSON.parse(JSON.stringify(userData));
        this.defaultUserEmail = JSON.parse(JSON.stringify(this.user.email));
      }

      this.isLoading = false;
    },
    async handleCreate() {
      this.isLoading = true;
      const createUser = await firebase.functions().httpsCallable("createUsers");

      if (this.uid) {
        // defaultUserEmail
        try {
          await firebase.firestore().collection("users").doc(this.uid).update({
            email: this.computedUser.email,
            first_name: this.computedUser.first_name,
            last_name: this.computedUser.last_name,
            date_of_birth: this.computedUser.date_of_birth,
            gender: this.computedUser.gender,
          });

          this.$router.push("/users");
        } catch (error) {
          console.log(error);
          this.toast.error(
            "Sorry, user update was not successful, please try again or contact support"
          );
        }
      } else {
        const entry = await createUser([
          {
            email: this.computedUser.email,
            phoneNumber: this.computedUser.phone_number,
          },
        ]);

        if (entry.data.success.length > 0) {
          await firebase.firestore().collection("users").doc(entry.data.success[0].uid).set({
            phone_number: this.computedUser.phone_number,
            email: this.computedUser.email,
            first_name: this.computedUser.first_name,
            last_name: this.computedUser.last_name,
            date_of_birth: this.computedUser.date_of_birth,
            gender: this.computedUser.gender,
          });

          this.$router.push("/users");
        } else {
          this.isLoading = false;
          console.log(entry.data.failed);
          this.toast.error(entry.data.failed[0].error.message);
        }
      }

      this.isLoading = false;
    },
    async handleDelete() {
      const confirmed = confirm(
        `Are you sure you want to delete user ${this.user.first_name} ${this.user.last_name} (${this.user.phone_number})?`
      );

      if (this.uid && confirmed) {
        this.isLoading = true;
        const deleteUser = await firebase.functions().httpsCallable("deleteUser");
        await deleteUser({ uid: this.uid })
          .then((result) => {
            if (result.data.isSuccess) {
              localStorage.setItem("deletedUser", this.uid);
              this.$router.push("/users");
            } else {
              this.toast.error(
                "Sorry, user deletion was not successful, please try again or contact support"
              );
            }
          })
          .catch((error) => {
            this.toast.error(
              "Sorry, user deletion was not successful, please try again or contact support"
            );
            console.log(error);
          });

        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.create-user-title {
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 42px;
  letter-spacing: 0.5px;
  color: #0ba3a9;
  margin-bottom: 24px;
}

.create-user-button {
  margin-top: 48px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  &.two-buttons {
    flex-direction: row;
    justify-content: space-between;
  }
}

.create-user-margin {
  margin-bottom: 25px;
}

.create-user-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}

.create-user-phone {
  position: relative;
}

.create-user-phone-action {
  position: absolute;
  top: 50%;
  right: 20px;
  user-select: none;
  cursor: pointer;
  transform: translateY(-50%);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #0ba3a9;
}

.error-block {
  color: #de1534;
  padding: 10px 15px;
  border-radius: 5px;
  background: #ffedf3;
  line-height: 1.5;
  font-size: 14px;
  margin-bottom: 25px;
}
</style>
