<template>
  <div class="base-rich-text" :class="{ 'has-error': error }">
    <div class="base-rich-text-title">
      {{ title }}
    </div>
    <div class="base-rich-text-header" v-if="editor">
      <button
        tabindex="-1"
        @click="editor.chain().focus().toggleBold().run()"
        :class="{ 'is-active': editor.isActive('bold') }"
      >
        Bold
      </button>
      <button
        tabindex="-1"
        @click="editor.chain().focus().toggleItalic().run()"
        :class="{ 'is-active': editor.isActive('italic') }"
      >
        Italic
      </button>
      <button
        tabindex="-1"
        @click="editor.chain().focus().setParagraph().run()"
        :class="{ 'is-active': editor.isActive('paragraph') }"
      >
        Paragraph
      </button>
      <button
        tabindex="-1"
        @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
        :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }"
      >
        Title
      </button>
      <button
        tabindex="-1"
        @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
        :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }"
      >
        Subtitle
      </button>
      <button
        tabindex="-1"
        @click="editor.chain().focus().toggleBulletList().run()"
        :class="{ 'is-active': editor.isActive('bulletList') }"
      >
        Bullet List
      </button>
      <button tabindex="-1" @click="editor.chain().focus().setHardBreak().run()">Break</button>
    </div>
    <div class="base-rich-text-body">
      <editor-content :editor="editor" />
    </div>
    <div v-if="errorText && error" class="error-text">{{ errorText }}</div>
  </div>
</template>

<script>
import { Editor, EditorContent } from "@tiptap/vue-3";
import StarterKit from "@tiptap/starter-kit";
import Placeholder from "@tiptap/extension-placeholder";

export default {
  components: {
    EditorContent,
  },

  props: {
    title: {
      type: String,
      default: "",
    },
    modelValue: {
      type: String,
      default: "",
    },
    error: {
      type: Boolean,
    },
    errorText: {
      type: String,
    },
  },

  data() {
    return {
      editor: null,
    };
  },

  watch: {
    modelValue(value) {
      const isSame = this.editor.getHTML() === value;

      if (isSame) {
        return;
      }

      this.editor.commands.setContent(value, false);
    },
  },

  mounted() {
    this.editor = new Editor({
      content: this.modelValue,
      extensions: [StarterKit, Placeholder],
      onUpdate: () => {
        this.$emit("update:modelValue", this.editor.getHTML());
      },
    });
  },

  beforeUnmount() {
    this.editor.destroy();
  },
};
</script>
<style lang="scss">
.base-rich-text {
  width: 100%;
  background: #ffffff;
  border: 1px solid rgba(166, 170, 180, 0.5);
  border-radius: 8px;
  padding: 24px;
  transition: border 0.2s ease;

  &.has-error {
    border-color: #de1534;
  }
}

.base-rich-text-title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #0ba3a9;
  margin-bottom: 24px;
}

.base-rich-text-header {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  .is-active {
    background: #0ba3a9;
    border: 1px solid #0ba3a9;
    color: #fff;
  }

  button {
    outline: none;
    padding: 4px 8px;
    border: 1px solid rgba(166, 170, 180, 0.5);
    background: #fff;
    border-radius: 6px;
  }
}

.base-rich-text-body {
  margin-top: 24px;
  width: 100%;

  & > div {
    width: 100%;
  }
}

.base-rich-text .ProseMirror {
  outline: none;
  border: 1px solid rgba(166, 170, 180, 0.5);
  border-radius: 6px;
  padding: 16px 16px;
  min-height: 300px;
  max-height: 300px;
  overflow-y: scroll;
  max-width: 100%;

  > * + * {
    margin-top: 0.75em;
  }

  li {
    padding: 0 24px;
    position: relative;
    font-size: 17px;
    line-height: 24px;
  }

  li:before {
    content: "";
    position: absolute;
    width: 8px;
    border-radius: 100px;
    height: 8px;
    background: #000;
    left: 0;
    top: 9px;
  }

  h1 {
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
  }

  h2 {
    font-weight: bold;
    font-size: 17px;
    line-height: 28px;
  }

  p {
    font-size: 17px;
    line-height: 24px;
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0d0d0d, 0.1);
    margin: 2rem 0;
  }
}

.ProseMirror p.is-editor-empty:first-child::before {
  content: attr(data-placeholder);
  float: left;
  color: #ced4da;
  pointer-events: none;
  height: 0;
}

.error-text {
  color: #de1534;
  margin-top: 20px;
  text-align: left;
}
</style>
