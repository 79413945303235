<template>
  <div v-if="isVisible" class="suspend-club">
    <div class="suspend-club-background" @click="handleCancel" />
    <div class="suspend-club-content">
      <div class="suspend-club-title">
        Changing the club status of <b>{{ name }}</b> to {{ text }}. Are you sure you want to
        continue?
      </div>
      <BaseInput
        v-model="phrase"
        :placeholder="`Type the word '${!inactive ? 'de' : ''}activate' to ${
          !inactive ? 'de' : ''
        }activate this account`"
      />
      <div class="suspend-club-row">
        <BaseButton theme="error" @click="handleCancel">Cancel</BaseButton>
        <BaseButton @click="handleConfirm" :disabled="disabledButton">Confirm</BaseButton>
      </div>
    </div>
  </div>
</template>

<script>
import BaseInput from "../BaseInput";
import BaseButton from "../BaseButton";

export default {
  name: "ChangePhone",
  components: {
    BaseInput,
    BaseButton,
  },
  props: {
    name: {
      type: String,
    },
    isVisible: {
      type: Boolean,
    },
    inactive: {
      type: Boolean,
    },
  },
  data() {
    return {
      phrase: "",
    };
  },
  computed: {
    disabledButton() {
      return this.inactive ? this.phrase !== "activate" : this.phrase !== "deactivate";
    },
    text() {
      if (this.inactive) {
        return "active will allow users to subscribe to your club";
      }
      return "inactive will let all subscriptions expire naturally without renewal";
    },
  },
  methods: {
    handleCancel() {
      this.phrase = "";
      this.$emit("cancel");
    },
    handleConfirm() {
      this.phrase = "";
      this.$emit("confirm");
    },
  },
};
</script>

<style lang="scss" scoped>
.suspend-club {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  height: 100%;
  width: 100%;

  .suspend-club-background {
    background: rgba(166, 170, 180, 0.6);
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .suspend-club-content {
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 0;
    width: 100%;
    min-height: 200px;
    background: #fff;
    border: 1px solid rgba(166, 170, 180, 0.5);
    padding: 32px 24px;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  .suspend-club-title {
    font-style: normal;
    font-weight: 300;
    font-size: 22px;
    line-height: 28px;
    letter-spacing: 0.1px;
    margin-bottom: 24px;
  }

  .suspend-club-row {
    margin-top: 32px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 16px;
  }

  .suspend-club-loading {
    width: 40px;
    height: 40px;
  }

  b {
    font-weight: 500;
  }
}
</style>
