import { createApp } from "vue";
import App from "./App.vue";
import router from "./router.js";
import Toast, { POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";
import "element-plus/lib/theme-chalk/index.css";
import ElementPlus from "element-plus";
import {
  backgroundImageFromStorageDirective,
  imageFromStorageDirective,
} from "@/tools/getImageFromStorage";

var toastOptions = {
  position: POSITION.TOP_RIGHT,
};

createApp(App)
  .use(router)
  .use(ElementPlus)
  .use(Toast, toastOptions)
  .directive("bg-image-from-storage", backgroundImageFromStorageDirective)
  .directive("image-from-storage", imageFromStorageDirective)
  .mount("#app");
