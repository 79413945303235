<template>
  <div class="container">
    <div class="heading">Klubba Card</div>
    <div v-loading="isLoading">
      <div class="row">
        <BaseInput v-model="card.program_id" label="Program ID" placeholder="Program ID" />
        <BaseInput v-model="card.tier_id" label="Tier name" placeholder="Tier name" />
      </div>
      <BaseButton @click="handleUpdateCard">Save card</BaseButton>
    </div>
  </div>
</template>

<script>
import firebase from "@/config/firebase";
import BaseInput from "@/components/BaseInput";
import BaseButton from "@/components/BaseButton";
import { useToast } from "vue-toastification";

export default {
  name: "Home",
  components: {
    BaseInput,
    BaseButton,
  },
  data() {
    const toast = useToast();
    return {
      toast: toast,
      isLoading: false,
      card: {
        tier_id: "",
        program_id: "",
      },
    };
  },
  methods: {
    async handleUpdateCard() {
      this.isLoading = true;
      const changeDefaultCard = await firebase.functions().httpsCallable("changeDefaultCard");
      const card = await changeDefaultCard(this.card);
      if (card.data.isSuccess) {
        this.card = card.data.result;
        this.isLoading = false;
      } else {
        this.toast.error("Error updating card: " + card.data.error.message);
        this.isLoading = false;
      }
    },
    async handleFetchCard() {
      this.isLoading = true;
      const getDefaultCard = await firebase.functions().httpsCallable("getDefaultCard");
      const card = await getDefaultCard();
      if (card.data.isSuccess) {
        this.card = card.data.result;
        this.isLoading = false;
      } else {
        this.toast.error("Error loading card: " + card.data.error.message);
        this.isLoading = false;
      }
    },
  },
  mounted() {
    this.handleFetchCard();
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 1110px;
  padding: 32px 0 90px;
  margin: 0 auto;
}

.row {
  margin-bottom: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
}

.heading {
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 42px;
  letter-spacing: 0.5px;
  color: #0ba3a9;
  margin-bottom: 24px;
}
</style>
