import { createRouter, createWebHistory } from "vue-router";

import Home from "@/pages/Home";
import Settings from "@/pages/Settings";
import Clubs from "@/pages/Clubs";
import Users from "@/pages/Users";
import CreateClub from "@/pages/CreateClub";
import CreateUser from "@/pages/CreateUser";
import SignIn from "@/pages/SignIn";

const routes = [
  {
    path: "/",
    component: Home,
  },
  {
    path: "/settings",
    component: Settings,
  },
  {
    path: "/sign-in",
    component: SignIn,
  },
  {
    path: "/clubs",
    component: Clubs,
    children: [
      {
        name: "CreateClub",
        path: "/clubs/create",
        component: CreateClub,
      },
      {
        name: "EditClub",
        path: "/clubs/edit/:id",
        component: CreateClub,
      },
    ],
  },
  {
    path: "/users",
    component: Users,
    children: [
      {
        name: "CreateUser",
        path: "/users/create",
        component: CreateUser,
      },
      {
        name: "EditUser",
        path: "/users/edit/:id",
        component: CreateUser,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
