<template>
  <div class="base-color-picker" :class="{ 'has-error': error }">
    <el-color-picker v-model="formValue" />
    <input v-model="formValue" class="base-color-picker-input" :placeholder="placeholder" />
    <div v-if="errorText && error" class="error-text">{{ errorText }}</div>
  </div>
</template>

<script>
export default {
  name: "BaseInput",
  props: ["modelValue", "placeholder", "error", "errorText"],
  computed: {
    formValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
};
</script>

<style lang="scss">
.base-color-picker {
  position: relative;
  width: 100%;

  &.has-error {
    .base-color-picker-input {
      border-color: #de1534;
    }
  }
}

.error-text {
  color: #de1534;
  margin-top: 20px;
  text-align: left;
}

.base-color-picker-input {
  width: 100%;
  font-style: normal;
  font-family: "Sarabun", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.2px;
  color: #323c47;
  border: 1px solid rgba(166, 170, 180, 0.5);
  border-radius: 6px;
  padding: 16px 20px 16px 60px;
  outline: none;
  transition: border-color 0.2s ease;
  will-change: border-color;
}

.base-color-picker .el-color-picker {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
}

.base-color-picker-input:active,
.base-color-picker-input:focus {
  border-color: #0ba3a9;
}

.base-color-picker-input::placeholder {
  color: rgba(166, 170, 180, 1);
}

.el-color-picker__panel {
  z-index: 9999 !important;
}
</style>
